
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[deviceType]/classes/first-landing/[activitySlug]",
      function () {
        return require("private-next-pages/[deviceType]/classes/first-landing/[activitySlug]/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[deviceType]/classes/first-landing/[activitySlug]"])
      });
    }
  